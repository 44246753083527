<template>
  <v-card
    class="d-flex flex-column flex-grow-1 pa-1">
    <v-card-title>Recent Ratings</v-card-title>
    <v-card-text>
      <school-select
        v-model="selectedSchoolId"
        default-to-first-value />
      <v-select
        v-if="rubricOptions.length"
        v-model="selectedRubricId"
        :items="rubricOptions"
        item-text="name"
        item-value="id"
        label="Rubric"
        outlined
        dense
        hide-details="auto"
        class="mt-1"
        style="width: 100%"/>
      <div v-if="selectedSchoolId && selectedRubricId && recentRubricsBySchoolIdByRubricId[selectedSchoolId][selectedRubricId] && recentRubricsBySchoolIdByRubricId[selectedSchoolId][selectedRubricId].length" class="d-flex flex-wrap mt-1">
        <v-col
          v-for="rubric in recentRubricsBySchoolIdByRubricId[selectedSchoolId][selectedRubricId].slice(0, 4)"
          cols="6"
          class="d-flex flex-column justify-center align-center pa-0 pt-2"
          :key="`${rubric.walk.id}-${rubric.id}`">
          <rubric-score
            :rubric="rubric"
            :size="80" />
          <div class="text-center">
            <router-link :to="`walks/walk/${rubric.walk.id}/complete`">
              {{ rubric.walk.timeCreated | formatDate('MM/DD') }} SchoolDog Walk
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center pa-0">
          <v-btn color="primary" text class="mt-2" @click="isViewAllDialogVisible = true">View all <v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-col>
      </div>
      <div v-else>
        <div
          class="text-subtitle-1 grey--text text--darken-1 pa-2 text-center">
          There are no recent ratings for this school.
        </div>
      </div>
    </v-card-text>
    <v-dialog v-model="isViewAllDialogVisible" max-width="600px">
      <v-card v-if="selectedRubric && selectedSchool && isViewAllDialogVisible">
        <v-card-title class="flex-nowrap align-start">
          {{ selectedRubric.name }} rubrics at {{ selectedSchool.name }}
          <v-spacer />
          <v-btn color="grey darken-1" icon @click="isViewAllDialogVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-wrap">
            <v-col
              v-for="rubric in recentRubricsBySchoolIdByRubricId[selectedSchoolId][selectedRubricId].slice(0, numVisibleRubrics)"
              cols="6"
              class="d-flex flex-column justify-center align-center pa-0 pt-2"
              :key="`${rubric.walk.id}-${rubric.id}`">
              <rubric-score
                :rubric="rubric"
                :size="80" />
              <div class="text-center">
                <router-link :to="`walks/walk/${rubric.walk.id}/complete`">
                  {{ rubric.walk.timeCreated | formatDate('MM/DD') }} SchoolDog Walk
                </router-link>
              </div>
            </v-col>
            <v-col v-if="hasMoreToShow" cols="12" class="d-flex justify-center pa-0">
              <v-btn color="primary" text class="mt-2" @click="numVisibleRubrics += 10">Show more</v-btn>
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="isViewAllDialogVisible = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SchoolSelect from '../common/SchoolSelect.vue'
import RubricScore from '../rubric/RubricScore.vue'

export default {
  components: { SchoolSelect, RubricScore },
  data () {
    return {
      selectedSchoolId: null,
      selectedRubricId: null,
      isViewAllDialogVisible: false,
      numVisibleRubrics: 10,
    }
  },
  computed: {
    ...mapState('app', [
      'usersById',
      'walks',
    ]),
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    recentRubrics() {
      const rubrics =  this.walks
        .filter(walk => 'complete' === walk.status)
        .flatMap(walk => walk.rubrics.map(rubric => ({ ...rubric, walk })))
        .filter(rubric => !rubric.skip)
        .sort((a, b) => a.walk.timeCreated > b.walk.timeCreated ? -1 : 1)
      return rubrics
    },
    recentRubricsBySchoolId() {
      const rubrics = this.recentRubrics
      const rubricsBySchoolId = {}
      rubrics.forEach(rubric => {
        if (!rubricsBySchoolId[rubric.walk.schoolId]) {
          rubricsBySchoolId[rubric.walk.schoolId] = []
        }
        rubricsBySchoolId[rubric.walk.schoolId].push(rubric)
      })
      return rubricsBySchoolId
    },
    recentRubricsBySchoolIdByRubricId() {
      const rubrics = this.recentRubrics
      const rubricsBySchoolIdByRubricId = {}
      rubrics.forEach(rubric => {
        if (!rubricsBySchoolIdByRubricId[rubric.walk.schoolId]) {
          rubricsBySchoolIdByRubricId[rubric.walk.schoolId] = {}
        }
        if (!rubricsBySchoolIdByRubricId[rubric.walk.schoolId][rubric.id]) {
          rubricsBySchoolIdByRubricId[rubric.walk.schoolId][rubric.id] = []
        }
        rubricsBySchoolIdByRubricId[rubric.walk.schoolId][rubric.id].push(rubric)
      })
      return rubricsBySchoolIdByRubricId
    },
    rubricOptions() {
      if (!this.selectedSchoolId) {
        return []
      }
      if (!this.recentRubricsBySchoolId[this.selectedSchoolId]) {
        return []
      }
      return this.recentRubricsBySchoolId[this.selectedSchoolId]
    },
    selectedRubric () {
      if (!this.selectedRubricId) {
        return null
      }
      return this.rubricOptions.find(rubric => rubric.id === this.selectedRubricId)
    },
    selectedSchool() {
      return this.getSchoolById(this.selectedSchoolId)
    },
    hasMoreToShow() {
      return this.recentRubricsBySchoolIdByRubricId[this.selectedSchoolId][this.selectedRubricId].length > this.numVisibleRubrics
    },
  },
  watch: {
    selectedSchoolId() {
      if (!this.recentRubricsBySchoolId[this.selectedSchoolId]) {
        this.selectedRubricId = null;
        return;
      }
      this.selectedRubricId = this.rubricOptions[0].id
    },
    isViewAllDialogVisible(val) {
      if (!val) {
        this.numVisibleRubrics = 10
      }
    },
  },
}
</script>

<style>
</style>
